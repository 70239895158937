<template>
  <div>
    <div class="mb-3">
      {{$t("leaderboard.players.description")}}
    </div>
    <WeekPicker v-if="vm.weekly" v-model="vm" @input="onWeekPicked" />
    <LeaderboardTable
      leaderboardType="players"
      :week="vm.week"
      :year="vm.year"
      :trophies="vm.weekly"
    />
  </div>
</template>

<script>
import LeaderboardTable from "../components/LeaderboardTable.vue";
import WeekPicker from "../components/WeekPicker.vue";

export default {
  components: {
    LeaderboardTable,
    WeekPicker,
  },

  props: {
    week: Number,
    year: Number,
    weekly: Boolean,
  },

  data() {
    return {
      vm: {
        weekly: false,
        year: null,
        week: null,
      },
    };
  },
  methods: {
    onWeekPicked: function(query) {
      this.$router.push({ path: "players", query: query });
    },
  },

  created() {
    Object.assign(this.vm, this.$props);
  },
};
</script>
