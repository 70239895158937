<template>
  <div>
    <b-table
      id="leaderboard-table"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      :borderless="true"
      :striped="true"
      :small="true"
      :hover="true"
      :fixed="true"
      :busy="loading"
    >
      <template v-slot:cell(rank)="data">
        {{ data.item.rank }}
        <i
          class="ml-1 fa fa-lg fa-trophy"
          style="color: orange"
          v-if="data.item.rank === 1 && showTrophies"
        ></i>
        <i
          class="ml-1 fa fa-trophy"
          style="color: silver"
          v-if="data.item.rank === 2 && showTrophies"
        ></i>
        <i
          class="ml-1 fa fa-trophy"
          style="color: brown"
          v-if="data.item.rank === 3 && showTrophies"
        ></i>
      </template>
      <template v-slot:cell(name)="data">
        <span v-if="leaderboardType == 'guilds'">
          <router-link
            v-if="data.item.name && data.item.id"
            :to="'/guild/' + data.item.id"
            >{{ data.item.name }}</router-link
          >
          <span v-else-if="data.item.name">
            {{ data.item.name }}
          </span>
          <span v-else class="text-muted">{{
            $t("leaderboardTable.hidden")
          }}</span>
          <a
            href="https://www.patreon.com/rinbot?fan_landing=true"
            target="_blank"
            ><i
              v-if="data.item.isBoosted"
              class="ml-2 fa fa-star"
              style="color: orange"
              v-b-tooltip.hover
              :title="$t('common.boostedByBotSupporter', $root.config)"
            ></i
          ></a>
        </span>
        <span v-else>
          <router-link
            v-if="data.item.name && data.item.userId"
            :to="'/profile/' + data.item.userId"
            >{{ data.item.name }}</router-link
          >
          <span v-else class="text-muted">{{
            $t("leaderboardTable.hidden")
          }}</span>
          <a
            href="https://www.patreon.com/rinbot?fan_landing=true"
            target="_blank"
            ><i
              v-if="data.item.isPatron"
              class="ml-2 fa fa-star"
              style="color: orange"
              v-b-tooltip.hover
              :title="$t('common.botSupporter', $root.config)"
            ></i
          ></a>
        </span>
      </template>
    </b-table>
    <b-pagination
      v-if="rows > perPage"
      v-model="currentPage"
      pills
      align="center"
      :hide-goto-end-buttons="true"
      :hide-ellipsis="true"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="leaderboard-table"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "LeaderboardTable",

  props: {
    leaderboardType: {
      type: String,
      default: null,
    },
    take: {
      type: Number,
      default: +100,
    },
    week: Number,
    year: Number,
    trophies: {
      default: false,
    },
  },
  data() {
    return {
      vm: {},
      loading: false,
      perPage: 20,
      currentPage: 1,
      items: [],
      fields:
        this.leaderboardType === "guilds"
          ? [
              {
                key: "rank",
                label: this.$t("leaderboardTable.rank"),
                thStyle:
                  "width: 52px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
              },
              {
                key: "name",
                label: this.$t("leaderboardTable.name"),
                thStyle: "width: 42%;",
              },
              {
                key: "points",
                label: this.$t("leaderboardTable.points"),
                thStyle:
                  "width: 20%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
              },
              {
                key: "rounds",
                label: this.$t("leaderboardTable.rounds"),
                thStyle:
                  "width: 14%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
              },
              {
                key: "players",
                label: this.$t("leaderboardTable.players"),
                thStyle:
                  "width: 14%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
              },
            ]
          : [
              {
                key: "rank",
                label: this.$t("leaderboardTable.rank"),
                thStyle:
                  "width: 52px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
              },
              {
                key: "name",
                label: this.$t("leaderboardTable.name"),
                thStyle: "width: 52%",
              },
              {
                key: "points",
                label: this.$t("leaderboardTable.points"),
                thStyle:
                  "width: 22%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
              },
              {
                key: "rounds",
                label: this.$t("leaderboardTable.rounds"),
                thStyle:
                  "width: 16%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
              },
            ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    showTrophies() {
      return this.trophies;
    },
    busy() {
      return this.vm.busy;
    },
  },

  watch: {
    $route: async function () {
      await this.refresh();
    },
    trophies: function () {
      this.vm.busy = true;
    },
  },

  methods: {
    refresh: async function () {
      this.loading = true;
      try {
        const params = {};
        if (this.week && this.year) {
          params.week = this.week;
          params.year = this.year;
        }
        else if(this.year) {
          params.year = this.year;          
        }
        const response = await this.axios.get(
          `/api/leaderboard/${this.leaderboardType}`,
          {
            params: params,
          }
        );
        this.items = response.data.ranked.slice(0, this.take);
      } catch (e) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
  },

  async mounted() {
    await this.refresh();
  },
};
</script>

<style>
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #eee;
}
td > span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
td > span > a {
  text-decoration: none;
}
td > span > a:hover {
  text-decoration: underline;
}
</style>
