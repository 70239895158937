<template>
  <div>
    <div class="mb-3">
      <Markdown :markdown="$t('leaderboard.guilds.description')" />
    </div>
    <WeekPicker v-if="vm.weekly" v-model="vm" @input="onWeekPicked" />
    <LeaderboardTable
      leaderboardType="guilds"
      :week="vm.week"
      :year="vm.year"
      :trophies="vm.weekly"
    />
  </div>
</template>

<script>
import LeaderboardTable from "../components/LeaderboardTable.vue";
import WeekPicker from "../components/WeekPicker.vue";
import Markdown from "../components/Markdown";

export default {
  components: {
    LeaderboardTable,
    Markdown,
    WeekPicker,
  },

  props: {
    week: Number,
    year: Number,
    weekly: Boolean,
  },

  data() {
    return {
      vm: {
        weekly: false,
        year: null,
        week: null,
      },
    };
  },
  methods: {
    onWeekPicked: function(query) {
      this.$router.replace({ path: "guilds", query: query });
    },
  },

  created() {
    Object.assign(this.vm, this.$props);
  },
};
</script>
